<template>
<!-- 优质供应商首页面 -->
<div class='content'>
  <div class="nav">
    <ul>
      <li v-for="(item,index) in headernav" :key="index">
        <i class="na-text" @click="this.$router.push(item.path)">{{item.text}}</i>
      </li>
    </ul>
  </div>
  <div class="main_cont">
    <div class="main_nav">
      <div id="sear">
        <el-input id="searin" v-model="inputValue" :placeholder="$t('m.search.commodity')"></el-input>
        <button class="sou" @click="sou">{{$t('m.search.search')}}</button>
      </div>
      <div id="logo">
        <!-- <img src="../../assets/jn.png" alt="exp2c.com"> -->
        <el-popover
          trigger="hover"
          :width="300"
        >
          <template #reference>
            <img :src="merchantMsg.icon" alt="exp2c.com">
          </template>
          <template #default>
            <div
              class="demo-rich-conent"
              style="display: flex; gap: 16px; flex-direction: column"
            >
              <el-avatar
                :size="60"
                :src="merchantMsg.icon"
                style="margin-bottom: 8px"
              />
              <div>
                <p
                  class="demo-rich-content__name"
                  style="margin: 0; font-weight: 500"
                >
                  {{merchantMsg.nickName}}
                </p>
                <p
                  class="demo-rich-content__mention"
                  style="margin: 0; font-size: 14px; color: var(--el-color-info)"
                >
                  {{merchantMsg.email}}
                </p>
              </div>

              <p class="demo-rich-content__desc" style="margin: 0">
                {{merchantMsg.supplierProfile}}
              </p>
            </div>
          </template>
        </el-popover>
      </div>
      <div id="select_company">
        {{merchantMsg.nickName}}
      </div>
    </div>
    <div class="main_select">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
        v-for="(i,k) in panList" :key="k"
        :label="i.label"
        :name="i.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="main_msg">
      <div class="msg_left">
        <div id="card_body">
          <div id="table_head">
            <tr v-for="(i,index) in tbtitle" :key="index">
              <th>{{i}}</th>
            </tr>
          </div>
          <vue-seamless-scroll :data="sctable"
              :class-option="optionHover"
              class="seamless-warp"
              v-loading="loading">
            <div id="table_body">
              <table v-if="sctable.length!=0">
                <tr
                v-for="(k,index1) in sctable" :key="index1">
                  <!-- <el-tooltip :content="k.productName"
                  placement="left" effect="light"> -->
                  <td v-text="k.productName"></td>
                  <!-- </el-tooltip> -->
                  <td style="color:#f04854;" v-text="k.price"></td>
                  <td v-text="k.amount"></td>
                  <td v-text="k.siteName"></td>
                  <td v-text="k.ts"></td>
                </tr>
              </table>
              <p class="nodata" v-else>{{$t('m.index.No_transaction_dynamics')}}</p>
            </div>
          </vue-seamless-scroll>
        </div>
      </div>
      <div class="msg_right">
        <div class="right_title">
          <span class="font_span">{{$t('m.special.Standard_price_action')}}</span>
          <el-select v-model="titleValue" :placeholder="$t('m.special.Please_select_a_variety')" @change="ttchange"
           size="medium">
            <el-option
              v-for="item in titleOptions"
              :key="item.value"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </div>
        <div class="right_echats">
          <div id="show"></div>
        </div>
      </div>
    </div>
    <div class="main_detail">
      <div class="detail_title">
        {{$t('m.special.Zone_recommendation')}}
      </div>
      <div class="detail_body">
        <div class="shaixuan">
          <span class="xuan">{{$t('m.personalcenter_myorder.tacitly_approve')}}</span>
          <ul class="selectul">
            <li class="selectli">
                <el-select v-model="salesvalue"
                placeholder="销量"
                @change="filtrateInquire('sale',salesvalue)"
                size="small">
                <el-option
                  v-for="item in optionsxiaoliang"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
            <li class="selectli">
                <el-select v-model="pricevalue"
                placeholder="价格"
                @change="filtrateInquire('price',pricevalue)"
                size="small">
                <el-option
                  v-for="item in optionsPrice"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
          <!-- <span class="xuan">筛选</span> -->
        </div>
        <div class="cont_body" v-loading="yushouLoading">
          <ul class="list">
            <li class="list_li" v-for="(item,index) in yushouList" :key="index">
              <img :src="item.pic" alt="">
              <span class="minispan">{{
                  item.previewStatus==0?'现货':
                  item.previewStatus==1?'预售':
                  item.previewStatus==3?'易货':''}}</span>
              <div class="right_cc">
                <div class="right_cc_left">
                  <p class="name">{{$t('m.index.product_name')}}：<strong>{{item.name}}</strong></p>
                  <p class="price_stock">
                    <span class="price">{{'￥' + item.price}}</span>
                    <span class="stock">{{$t('m.reservation_varietiy_details.guigeStock')}}：{{item.stock + item.unit}}</span>
                  </p>
                  <p class="area_time">
                    <span class="area">{{item.previewStatus==3 ? $t('m.barter_detail.place_of_delivery')+item.deliveryPlace :`${$t('m.merchantworkbench.producer')}：`+item.originPlace}}</span>
                    <span class="time">{{$t('m.reservation_varietiy_details.Validity_of_Quotation')}}{{item.offerExpiryDate}}</span>
                  </p>
                </div>
                <div class="right_cc_right">
                  <span class="btnspan">
                    <div class="btn_div">
                      <button class="gobutton gm" :style="`left:${$t('m.reservation_varietiy_details.attentionLeft')}`"
                          @click="toDetails(item)">
                          {{$t('m.special.rush_to_purchase')}}
                      </button>
                      <button class="gobutton gz"
                          @click="toDetails(item)">
                          <img src="../../static/ossImg/huo2.gif" alt="">
                      </button>
                    </div>
                  </span>
                </div>
              </div>
            </li>
            <div class="nodata" v-if="yushouList.length == 0">
              <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%95%B0%E6%8D%AE%E4%B8%BA%E7%A9%BA.png" alt="">
            </div>
          </ul>
        </div>
      </div>
      <div class="fenye">
        <div class="showfooter" v-if="totalnum<pagesize?false:true">
          <el-pagination
            v-model:currentPage="currentPage"
            :page-size="pagesize"
            layout="prev, pager, next, jumper"
            :total="totalnum"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="divfooter">
    <Footer/>
  </div>
</div>
</template>

<script>
import { reactive, ref, toRaw } from 'vue'
import * as echarts from 'echarts'
import http from '../../api/http'
import Footer from '../index/Footer.vue'
import moment from 'moment'
// import { removeStore, setStore } from '../../tools/storage'
import i18n from '@/tools/i18n.js'
const { t } = i18n.global
const headernav = [
  { icon: '', text: t('m.Header.home_page'), path: '/' },
  { icon: '', text: t('m.Header.login_again'), path: '/loginandregister' },
  { icon: '', text: t('m.Header.mobile_terminal'), path: '/' }
  // { icon: '', text: '切换商家端', path: '/merchantworkbench' }
]
// const tbtitle = ['商品名称', '成交价格', '成交量', '地区', '成交时间']
const tbtitle = [
  t('m.index.product_name'),
  t('m.index.transaction_price'),
  t('m.index.turnover'),
  t('m.index.region'),
  t('m.index.Transaction_time')
]
const optionsPrice = ref([{ value: '降序', label: '价格降序' }, { value: '升序', label: '价格升序' }])
const optionsxiaoliang = ref([{ value: '降序', label: '销量降序' }, { value: '升序', label: '销量升序' }])
// 获取成交明细
const getSuccessOrderList = (data) => {
  return http.get('/mall-portal/home/getSuccessOrderList', data)
}
// 品种(专题)数据
const topicList = (data) => {
  return http.get('/mall-portal/home/topicList')
}
// // 根据商户id查专区商品
// const getSubjectListByMerchantId = (id) => {
//   return http.get(`/mall-portal/product/getSubjectListByMerchantId/${id}`)
// }
// 根据商家id查询商家信息
const queryMerchantLiveBroadcastInformationBasedOnMerchantId = (data) => {
  return http.get('/mall-portal/aliyunLive/queryMerchantLiveBroadcastInformationBasedOnMerchantId', data)
}
// 查用户专区商品
const search = (data) => {
  return http.get('/mall-portal/product/search', data)
}
// 走势图
const queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId = (data) => {
  return http.get('/mall-portal/home/queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId', data)
}
export default {
  name: 'SpecialMain',
  data () {
    return {
      inputValue: ref(''), // 输入框的值

      activeName: 'special',
      panList: [
        { label: this.$t('m.special.Zone_homepage'), name: 'special' },
        // { label: this.$t('m.search.Capacity_presale'), name: 'prsell' },
        { label: this.$t('m.search.Spot_listing'), name: 'cash' },
        // { label: this.$t('m.special.Resale_hall'), name: 'reseller' },
        { label: this.$t('m.special.Auction_hall'), name: 'Auction' }
        // { label: this.$t('m.special.Barter_hall'), name: 'barter' }
      ], // 订单状态标题内容
      sctable: ref([]),
      loading: ref(true),
      titleOptions: ref([]),
      titleValue: ref(''), // 走势选择框选中的值

      chartsSetOption: reactive({ // echarts图示数据
        // title: {
        //   text: '行情展示'
        // },
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
        },
        yAxis: {
          type: 'value',
          // max: 'dataMax',
          width: 200,
          nameTextStyle: {
            overflow: 'breakAll'
          },
          splitNumber: 7
        },
        series: [
          {
            // smooth: true,
            type: 'line',
            stack: 'Total',
            symbol: 'circle', // 改为实心点
            data: [],
            itemStyle: {
              color: '#c5854d', // 折线点的颜色
              lineStyle: {
                color: '#d3bea5'// 折线的颜色
              }
            }
          }
        ]
      }),

      aaaa: ref(''),
      // 筛选
      salesvalue: ref(''),
      pricevalue: ref(''),
      // sort: null, // 排序字段:0->按相关度；1->销量从低到高；2->销量从高到低；3->价格从低到高；4->价格从高到低
      paramsList: {
        sort: null, // 排序字段:0->按相关度；1->销量从低到高；2->销量从高到低；3->价格从低到高；4->价格从高到低
        pageSize: 20,
        pageNum: 1,
        previewStatus: 0,
        merchantId: null
      },

      merchantMsg: '', // 供应商信息

      // 分页
      // 存放总数据个数
      totalnum: ref(0),
      // 存放一页显示个数
      pagesize: ref(20),
      // 默认展示页
      currentPage: ref(1),
      yushouList: reactive([]), // 展示数据
      yushouLoading: ref(true) // 数据loading
    }
  },
  components: { Footer },
  computed: {
    optionHover () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: 7, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  methods: {
    async initdata () { // 初始化交易动态信息
      const data = {
        memberId: this.$route.query.memberId
      }
      const getList = await getSuccessOrderList(data)
      this.sctable = getList.data.data
      this.loading = false
      // console.log(this.sctable)
    },
    async initMerId (data) { // 初始化用户商品信息
      // console.log(this.$route.query.memberId)
      this.yushouLoading = true
      const res = await search(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.totalnum = res.data.data.total
        this.yushouList = res.data.data.list
        console.log(this.yushouList)
        this.yushouList.forEach(item => {
          item.offerExpiryDate = moment(item.offerExpiryDate).format('YYYY-MM-DD')
        })
        this.yushouLoading = false
        // console.log(this.yushouList)
      }
    },
    async initUserId () { // 初始化根据商户id查商户信息
      const data = {
        merchantId: this.$route.query.memberId
      }
      const res = await queryMerchantLiveBroadcastInformationBasedOnMerchantId(data)
      console.log(res)
      if (res.status === 200) {
        this.merchantMsg = res.data
      }
    },
    async initTopList () { // 获取品种
      const res = await topicList()
      console.log(res)
      if (res.data.code === 200) {
        this.titleOptions = res.data.data
      }
    },
    async initCharts (id) { // 初始化走势图
      const data = {
        varietyId: id || '',
        merchantId: this.$route.query.memberId
      }
      const res = await queryTheStandardPriceTrendAccordingToTheVarietyAndMerchantId(data)
      // console.log(res)
      const da = res.data.data
      if (res.data.code === 200) {
        this.chartsSetOption.series[0].data = [
          da.januaryAveragePrice,
          da.februaryAveragePrice,
          da.marchAveragePrice,
          da.aprilAveragePrice,
          da.mayAveragePrice,
          da.juneAveragePrice,
          da.julyAveragePrice,
          da.augustAveragePrice,
          da.septemberAveragePrice,
          da.octoberAveragePrice,
          da.novemberAveragePrice,
          da.decemberAveragePrice
        ]
        // console.log(this.chartsSetOption)
        this.getOptions()
      }
    },
    handleClick (tab, event) {
      const PageId = document.querySelector('.detail_title')
      switch (tab.props.name) {
        case 'special':
          this.currentPage = 1
          this.pricevalue = ''
          this.salesvalue = ''
          this.initMerId({
            previewStatus: 0,
            merchantId: this.$route.query.memberId,
            pageSize: 20,
            pageNum: this.currentPage
          })
          break
        case 'prsell':
          this.currentPage = 1
          this.paramsList.pageNum = 1
          this.paramsList.previewStatus = 1
          this.initMerId(this.paramsList)
          window.scrollTo({
            top: PageId.offsetTop - 20,
            behavior: 'smooth'
          })
          break
        case 'cash':
          this.currentPage = 1
          this.paramsList.pageNum = 1
          this.paramsList.previewStatus = 0
          this.initMerId(this.paramsList)
          window.scrollTo({
            top: PageId.offsetTop - 20,
            behavior: 'smooth'
          })
          break
        case 'reseller':
          this.$router.push('/resell')
          break
        case 'Auction':
          this.$router.push('/auction')
          break
        case 'barter':
          this.$router.push('/barter')
          break
        default:
          break
      }
    },
    filtrateInquire (name, item, tagslist) {
      console.log('筛选触发')
      console.log(name)
      console.log(item)
      this.currentPage = 1

      switch (name) {
        case 'sale':
          this.pricevalue = ''
          if (item === '升序') {
            this.paramsList.sort = 1
          } else {
            this.paramsList.sort = 2
          }
          break
        case 'price':
          this.salesvalue = ''
          if (item === '升序') {
            this.paramsList.sort = 3
          } else {
            this.paramsList.sort = 4
          }
          break
        default:
          break
      }
      this.paramsList = toRaw(this.paramsList)

      this.initMerId(this.paramsList)
    },
    handleCurrentChange (val) { // 分页切换
      // console.log(`current page: ${val}`)
      this.currentPage = val
      this.paramsList.pageNum = this.currentPage
      this.initMerId(this.paramsList)
    },
    // echarts表格实现方法
    getOptions () {
      const mychart = echarts.init(document.getElementById('show'))
      mychart.setOption(this.chartsSetOption)
    },
    ttchange (e) {
      // console.log(e)
      this.initCharts(e)
    },
    toDetails (data) { // 到详情购买页面
      console.log(data)
      // this.$emit('dataToDetail', data)
      if (data.previewStatus === 0) { // 现货
        this.$router.push({
          path: '/cashcommodity/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 1) { // 预售
        this.$router.push({
          path: '/reservation/varietiy_details',
          query: {
            productSn: data.productSn
          }
        })
      } else if (data.previewStatus === 3) { // 易货
        this.$router.push({
          path: '/barter/detail',
          query: {
            id: data.id
          }
        })
      }
    },
    sou () {
      console.log(this.inputValue)
      this.$router.push({
        path: '/search',
        query: { sedata: 0, data: this.inputValue }
      })
    }
  },
  mounted () {
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部

    this.paramsList.merchantId = this.$route.query.memberId
    this.initdata()
    this.initTopList()
    this.initMerId({
      merchantId: this.$route.query.memberId,
      previewStatus: 0,
      pageSize: 20,
      pageNum: this.currentPage
    })
    this.initCharts()
    this.initUserId()
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange, headernav, tbtitle, optionsxiaoliang, optionsPrice
    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.content{
   background: #f2efed;
  //  height: 800px;
  .nav{
    background: #ffffff;
    height: 30px;
    ul{
      width: 1200px;
      margin: 0 auto;
      display: block;
      line-height: 30px;
      li{
        float: left;
        display: flex;
        // width: 56px;
        height: 30px;
        margin-right: 20px;
        cursor: pointer;
        .na-text{
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
        }
      }
    }
  }
  .main_cont{
    display: flex;
    flex-flow: wrap;
    background: #ffffff;
    width: 1200px;
    margin: 0 auto;
    margin-bottom: 20px;
    .main_nav{
      width: 100%;
      height: 130px;
      box-sizing: border-box;
      padding: 0 30px 0;
      display: flex;
      align-items: center;
      #sear{
        margin: 0 20px;
        width: 440px;
        // flex: 1;
        display: flex;
        align-items: center;
        /deep/ #searin{
          border: 1px solid #666666;
          box-sizing: border-box;
          // color: #FFFFFF;
          background: 0 !important;
          flex: 1;
          height: 38px;
          padding: 0 15px;
          outline: none;
          border-radius: 0;
        }
        .sou{
          // font-size: 14px;
          font-family: Source Han Sans SC;
          font-weight: 400;
          width: 107px;
          background: #3B1C01;
          color: #ffffff;
          height: 38px;
        }
      }
      #logo{
          margin-left: 50px;
          // background: #666666;
        img{
          margin-left: 50px;
          width: 125px;
          cursor: pointer;
        }
      }
      #select_company{
        padding: 20px;
        flex: 1;
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }
    }
    /deep/ .main_select{
      .el-tabs{
        .el-tabs__header{
          .el-tabs__nav-wrap{
            .el-tabs__nav-scroll{
              display: flex;
              justify-content: center;
              .el-tabs__nav{
                margin: 0 auto;
                height: 70px;
                .el-tabs__active-bar{
                  background: #C6824E;
                }
                .el-tabs__item{
                  width: 400px;
                  text-align: center;
                  font-size: 18px;
                  font-weight: bold;
                  color: #C6824E;
                  padding: 0;
                }
                .is-active{
                  color: #C6824E;
                }
                .el-tabs__item:hover{
                  color: #c49979;
                }
              }
            }
          }
        }
      }
    }
    .main_msg{
      width: 100%;
      display: flex;
      padding: 10px 0 20px;
      .msg_left{
        width: 680px;
        #card_body{
          // height: 320px;
          // // overflow: hidden;
          font-size: 14px;
          font-family: Microsoft YaHei;
          #table_head{
            // padding: 0 10px;
            display: flex;
            // padding-top: 20px;
            tr{
              width: 100%;
              display: flex;
              height: 40px;
              justify-content: center;
              line-height: 40px;
              // text-align: center;
              th{
                flex: 1;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #AC703B;
              }
            }
          }
          .seamless-warp{
            height: 320px;
            overflow: hidden;
            // margin: 10px 0;
            #table_body{
              display: flex;
              align-items: center;
              // justify-content: center;
              table{
                flex: 1;
                display: flex;
                flex-flow: column;
                font-size: 12px;
                padding: 0 10px;
                tr{
                  height: 40px;
                  flex-shrink: 0;
                  border-bottom: 1px solid #cccccc;
                  display: flex;
                  td{
                    text-align: center;
                    flex: 1;
                    line-height:40px;
                    white-space:nowrap;
                    text-overflow: ellipsis;
                  }
                }
                tr:hover{
                  // cursor: pointer;
                  background: rgba(255, 255, 255, 0.05);
                }
              }
              .nodata{
                flex: 1;
                font-size: 20px;
                height: 300px;
                line-height: 300px;
                font-weight: bold;
                color: #C6824E;
                // justify-content: center;
                text-align: center;
              }
            }
          }
        }
      }
      .msg_right{
        flex: 1;
        padding: 0 20px;
        padding-left: 70px;
        display: flex;
        flex-flow: wrap;
        .right_title{
          height: 40px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-left: 50px;
          .font_span{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .right_echats{
          width: 100%;
          height: 320px;
          #show{
            width: 430px;
            height: 320px;
          }
        }
      }
    }
    .main_detail{
      width: 100%;
      .detail_title{
        height: 50px;
        line-height: 50px;
        text-align: center;
        background: #AC703B;
        color: #FFFFFF;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
      }
      .detail_body{
        .xuan{
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .shaixuan{
          padding: 0 20px;
          display: flex;
          height: 59px;
          justify-content: right;
          align-items: center;
          border-bottom: 1px solid #F2EFED;
          .selectul{
            padding: 0 50px;
            flex: 1;
            display: flex;
            width: 220px;
            // justify-content: space-between;
            .selectli{
              width: 100px;
              margin-right: 20px;
            }
          }
        }
        .cont_body{
          .list{
            padding:20px 0;
            .list_li{
              display: flex;
              align-items: center;
              padding-left: 30px;
              position: relative;
              .minispan{
                width: 30px;
                height: 20px;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                line-height: 20px;
                background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
                border-radius: 0px 0px 4px 0px;
                position: absolute;
                top: 20px;
              }
              img{
                width: 110px;
                height: 110px;
              }
              .right_cc{
                flex: 1;
                display: flex;
                height: 110px;
                padding: 20px;
                border-bottom: 1px solid #9999997b;
                .right_cc_left{
                  flex: 1;
                  display: flex;
                  flex-wrap: wrap;
                  align-items: center;
                  p{
                    width: 100%;
                    font-size: 20px;
                  }
                  .name{
                    color: #666666;
                    font-size: 18px;
                    strong{
                      font-size: 20px;
                      font-weight: bold;
                      color: #333333;
                    }
                  }
                  .price_stock{
                    span{
                      font-weight: bold;
                      color: #333333;
                    }
                    .price{
                      display: inline-block;
                      width: 350px;
                      color: #ff1818;
                    }
                  }
                  .area_time{
                    color: #333333;
                    span{
                      font-weight: bold;
                      color: #333333;
                    }
                    .area{
                      display: inline-block;
                      width: 350px;
                    }
                  }
                }
                .right_cc_right{
                  width: 180px;
                  display: flex;
                  align-items: center;
                  .btnspan{
                    width: 100%;
                    display: flex;
                    .btn_div{
                      float: right;
                      width: 100%;
                      position: relative;
                      height: 52px;
                      background: url('../../static/imges/42559e2c11e8b82ba91e451581be42b.png') no-repeat;
                      .gobutton{
                        position: absolute;
                        top: 12px;
                        font-size: 14px;
                        height: 30px;
                        background: transparent;
                        border-radius: 4px;
                      }
                      .gz{
                        left: 33px;
                        color: #673000;
                        img{
                          margin-top: -25px;
                          margin-left: -17px;
                          height: 65px;
                          width:65px;
                        }
                      }
                      .gm{
                        left: 110px;
                        color: #F2EFED;
                      }
                    }
                  }
                }
              }
            }
            .nodata{
              display: flex;
              justify-content: center;
            }
          }
        }
      }
      /deep/ .fenye{
        .showfooter{
          height: 100px;
          // background: red;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-pagination{
            // line-height: 100px;
            .el-pager{
              margin: 0 20px;
              .number,.btn-quicknext{
                margin: 0 5px;
                width: 40px;
                height: 40px;
                border: 1px solid transparent;
                line-height: 40px;
              }
              .active{
                background: #ffffff;
                color: #C6824E;
                border-color: #C6824E;
              }
              .number:hover{
                color: #C6824E;
              }
              .el-icon{
                height: 40px;
                line-height: 40px;
              }
            }
            .btn-prev,.btn-next,.el-pagination__jump{
              height: 40px;
              line-height: 40px;
              .el-input{
                height: 40px;
                .el-input__inner{
                  height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
  .divfooter{
    background: #280E01;
  }

}
</style>
